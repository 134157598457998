import {mapActions, mapGetters, mapMutations} from "vuex";

//sections
import catalogSection from "@/modules/catalog/section/catalog-section/index.vue";
import {dynamicHead} from "../../mixins/dynamic-head";


export default {
    name: "catalog",
    components: {
        catalogSection,
    },
    mixins: [dynamicHead],
    data() {
        return {}
    },
    mounted() {
        document.title = `${this.$t('catalog.title')} | karbosnab`
    },
    created() {
        document.getElementById('html').classList.remove('hide')
        this.getPageContent('categories').then(() => this.setPageItem(this.pageContent))
        this.setRequestFlag(true);
    },
    computed: {
        ...mapGetters({
            globalRequestsFlag: 'system/globalRequestsFlag',
            pageContent: 'setting/pageContent',
        })
    },

    methods: {
        ...mapActions({
            getPageContent: 'setting/GET_PAGE_CONTENT'
        }),
        ...mapMutations({
            setRequestFlag: 'system/SET_REQUESTS_FLAG',

        })
    }
}
